@import "~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references";
.support-overlay-content{
	background-color: $ms-color-white;
	// bottom: 45%;
	position: absolute;
	border-width: thin;
    border-style: solid;
    border-radius: 2px;
	box-shadow: 1px 1px 5px 1px $ms-color-card-border;
	padding : 10px;
	justify-content: center;
	margin-left: 25%;
	margin-right: 25%;

	.suuppor-header-stack{
		justify-content: space-between;
	.support-ticket-title{
		font-size: 18px;
		font-weight: 600;
	}
}

	.support-overlay-buttons-stack {
		.button-stack-item {
			padding: 10px 5px;
		}
		float: right;
	}
}

.support-overlay-modal
{
	z-index: 1000;
	background-color: rgba(255, 255, 255, 0.6);
}

