@import "~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references";
.feedback-modal-content {
	background-color: $ms-color-white;
	//bottom: 20%;
	position: absolute;
	border-width: thin;
	border-style: solid;
	border-radius: 2px;
	box-shadow: 1px 1px 5px 1px $ms-color-card-border;
	margin: 0 10% 0 5%;
	padding: 10px;	

	.feedback-modal-header-stack {
		justify-content: space-between;

		.feedback-modal-title {
			font-size: 18px;
			font-weight: 500;
		}
	}

	.feedback-modal-buttons-stack {
		.button-stack-item {
			padding: 10px 5px;
		}

		float: right;
	}
}
.upvote{	
	
}

.downvote{	
	
}

.feedback-overlay-modal {
	z-index: 1000;
	background-color: rgba(255, 255, 255, 0.6);
	//small screen 320px - 479px
	@media only screen and (max-width: 50px) and (min-width: 500px) {
		padding: 0px;
		margin-top: 10px;
		border-left: 1px solid $ms-color-black-different-shade-A6;
	}

	a:link {
		text-decoration: underline;
	}
}
